import React, { useState } from 'react';
import { Button, Modal, Header, Form, Input } from 'semantic-ui-react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import Axios from 'axios';
import apiString from './apiString';

const env = 'production';
const client = {
    sandbox: 'AZ29y8PpNpAaBAaDNRtrFW3UCNm7uLBQ5SQjwgAigeS5-xIUt1w5sSneXcLhO8W2IkZ8K78HsYGu6Ews',
    production: 'AVWH2KOc-yvKsKwVUlMvOUw69lm2_WIj13fceUqOXSlVbfLJ63e0VWBr97upz2Mic7NoF3V4Gubny3JR'
};
const currency = 'USD';


const DonationCheckout = ({setDonateModal, setLoading}) => {
    const [contactInfo, setContactInfo] = useState({name: '', email: ''});
    const [donation, setDonation] = useState(0);
    const [page, setPage] = useState(1);

    const handleFormChange = (e, {id, value}) => {
        setContactInfo({...contactInfo, [id]: value});
    }

    const validateEmail  = () => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!regex.test(contactInfo.email)) setContactInfo({...contactInfo, email: ''});
    }

    const checkPage1Disabled = () => {
        return (contactInfo.name === '' || contactInfo.email === '');
    }

    const checkPage2Disabled = () => {
        return (isNaN(donation) || +donation < 1);
    }

    const payPalError = error => {
        console.log(error);
        setPage(4);
    }

    const payPalCancel = data => {
        console.log(data);
    }

    const payPalSuccess = payment => {
        setLoading(true);
        const formData = new FormData();
        formData.set('action', 'handleDonation');
        formData.set('donation', donation.toFixed(2));
        formData.set('contactInfo', JSON.stringify(contactInfo));
        Axios.post(apiString, formData).then(() => {
            setPage(5);
            setLoading(false);
        });
    }

    const checkDonation = () => {
        if (isNaN(donation) || +donation < 1) setDonation(0);
        else setDonation(+donation);
    }

    const clearDonation = () => {
        if (donation === 0) setDonation('');
    }

    return (
        <>
            {page === 1 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setDonateModal(false)}>
                    <Header as="h3" textAlign="center">Contact Info</Header>
                    <Modal.Content>
                        <Form style={{width: 250, margin: '0 auto'}}>
                            <Form.Field id="name" control={Input} value={contactInfo.name} label="Contact Name" fluid onChange={handleFormChange} />
                            <Form.Field id="email" control={Input} value={contactInfo.email} label="Contact Email" fluid onChange={handleFormChange} onBlur={validateEmail} />
                        </Form> 
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setPage(2)} disabled={checkPage1Disabled()}>Next</Button>
                        <Button negative onClick={() => setDonateModal(false)}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
            }
            {page === 2 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setDonateModal(false)}>
                    <Header as="h3" textAlign="center">Donation Amount</Header>
                    <Modal.Content>
                        <p>Please enter the amount you would like to donate.  Due to the fees involved, we can't accept donations less than $1.</p>
                        <Form style={{width: 250, margin: '0 auto'}}>
                            <Form.Field id="donation" control={Input} value={donation} label="Donation $" fluid onChange={e => setDonation(e.target.value)} onBlur={checkDonation} onFocus={clearDonation} />
                        </Form> 
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setPage(1)}>Back</Button>
                        <Button color="blue" onClick={() => setPage(3)} disabled={checkPage2Disabled()}>Next</Button>
                    </Modal.Actions>
                </Modal>
            }
            {page === 3 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setDonateModal(false)}>
                    <Header as="h3" textAlign="center">Checkout</Header>
                    <Modal.Content>
                        <p style={{width: '100%', textAlign: 'center', marginBottom: 20}}>Click the Paypal button below to pay your total of <strong>${donation.toFixed(2)}</strong></p>
                        <div style={{width: 300, margin: '0 auto'}}>
                            <PaypalExpressBtn style={{color: 'blue', shape: 'rect', size: 'responsive', width: 200}} shipping="1" env={env} client={client} currency={currency} total={donation.toFixed(2)} onError={payPalError} onSuccess={payPalSuccess} onCancel={payPalCancel} />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setPage(2)}>Back</Button>
                        <Button negative onClick={() => setDonateModal(false)}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
            }
            {page === 4 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setDonateModal(false)}>
                    <Header as="h3" textAlign="center">Paypal Error</Header>
                    <Modal.Content>
                        <p>Paypal is not responding.  This usually means there is a connection issue on your end or something is wrong with Paypal.  You can try again by clicking the "Back" button below.</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setPage(3)}>Back</Button>
                        <Button negative onClick={() => setDonateModal(false)}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
            }
            {page === 5 &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setDonateModal(false)}>
                    <Header as="h3" textAlign="center">Donation Successful!</Header>
                    <Modal.Content>
                        <p>Thank you for your donation!</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setDonateModal(false)}>Done</Button>
                    </Modal.Actions>
                </Modal>
            }
        </>
    );
}

export default DonationCheckout;