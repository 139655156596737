import Contact from '../components/contact';
import logoImage from '../images/games-logo.jpg';

const Home = () => {
    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
            <p>Welcome to the website for the Phoenix Open Championships weekend held at the 59th Phoenix Scottish Highland Games in Gilbert, AZ.  They take place on March 2nd and 3rd, 2024.</p>
            <a href="https://phoenixscottishgames.com/" target="_blank" rel="noreferrer"><img src={logoImage} alt="games-logo" width="50%" /></a>
            <Contact />
        </div>
    );
}

export default Home;