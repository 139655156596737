import { useEffect, useState } from 'react';
import { Button, Modal, Header, Form, Input, Select, TextArea, Checkbox } from 'semantic-ui-react';
import registrantTemplate from './registrantTemplate';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

const classifications = ['Primary', 'Beginner', 'Novice', 'Intermediate', 'Premier'];
const classificationList = classifications.map(type => ({key: type, text: type, value: type}));
classificationList.unshift({key: '', text: '', value: '', disabled: true});

const Registration = ({setModal, masterDanceList, dancerIndex, dancerInfo, addDancerToCart, modifyDancerInCart, cart}) => {

    const [confirmModal, setConfirmModal] = useState(false);
    // const [registrant, setRegistrant] = useState(dancerIndex === 'new' ? {...registrantTemplate} : {...dancerInfo});
    const [registrant, setRegistrant] = useState(dancerIndex === 'new' ? structuredClone(registrantTemplate) : structuredClone(dancerInfo));
    const [page, setPage] = useState(1);
    const [danceList, setDanceList] = useState([]);

    useEffect(() => {
        if (dancerIndex === 'new') {
            const newRegistrant = {...registrant};
            for (let i = 0; i < masterDanceList.length; i++) {
                const dance = masterDanceList[i];
                newRegistrant.dances[dance.dance] = false;
            }
            if (cart.length > 0) {
                newRegistrant.teacherEmailAddress = cart[cart.length-1].teacherEmailAddress;
                newRegistrant.teacherLastName = cart[cart.length-1].teacherLastName;
                newRegistrant.address = cart[cart.length-1].address;
            }
            setRegistrant(newRegistrant);
        }
    }, []);


    const handleFormChange = (e, {id, value}) => {
        setRegistrant({...registrant, [id]: value});
    }

    const validateEmail  = () => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!regex.test(registrant.teacherEmailAddress)) setRegistrant({...registrant, teacherEmailAddress: ''});
    }

    const handleSubmit = () => {
        if (dancerIndex === 'new') addDancerToCart(registrant);
        else modifyDancerInCart(dancerIndex, registrant);
        setModal('');
    }

    const preparePageTwo = () => {
        const list = {0: [], 1: []};
        for (let i = 0; i < masterDanceList.length; i++) {
            const dance = masterDanceList[i];
            if (dance[registrant.classification] !== '9999') list[+dance.day-1].push({name: dance.dance, price: +dance[registrant.classification]});
        }
        setDanceList(list);
        setPage(2);
    }

    const handleDanceClick = (danceName, dancePrice) => {
        const newRegistrant = {...registrant};
        newRegistrant.dances[danceName] = !newRegistrant.dances[danceName];
        newRegistrant.total = newRegistrant.total + (newRegistrant.dances[danceName] ? +dancePrice : -dancePrice);
        setRegistrant(newRegistrant);
    }

    const checkPage1Disabled = () => {
        const {firstName, lastName, birthDate, address, classification, teacherLastName, teacherEmailAddress, scotsDanceNumber} = registrant;
        return (firstName === '' || lastName === '' || birthDate === '' || address === '' || classification === '' || teacherLastName === '' || teacherEmailAddress === '' || scotsDanceNumber === '');
    }

    const checkPage2Disabled = () => {
        const {dances} = registrant;
        let count = 0;
        const danceKeys = Object.keys(dances);
        for (let i = 0; i < danceKeys.length; i++) {
            if (dances[danceKeys[i]]) count++;
        }
        return count === 0;
    }

    const checkPage3Disabled = () => {
        return registrant.signature === '';
    }

    return (
        <Modal open closeOnDimmerClick={false} closeOnEscape={false} closeIcon onClose={() => setConfirmModal(true)}>
            {page === 1 &&
                <>
                    <Header as="h3" textAlign="center">Dancer Info</Header>
                    <Modal.Content>
                        <Form style={{width: 250, margin: '0 auto'}}>
                            <Form.Field id="firstName" control={Input} value={registrant.firstName} label="First Name" fluid onChange={handleFormChange} />
                            <Form.Field id="lastName" control={Input} value={registrant.lastName} label="Last Name" fluid onChange={handleFormChange} />
                            <Form.Field id="birthDate" control={SemanticDatepicker} value={registrant.birthDate} label="Birth Date (mm/dd/yyyy)" fluid onChange={handleFormChange} format="MM/DD/YYYY" showToday={false} />
                            <Form.Field id="address" control={TextArea} value={registrant.address} label="Address (include country if outside USA)" placeholder="Example:&#10;123 N Sesame Street&#10;Anytown, IL 12345" onChange={handleFormChange} />
                            <Form.Field id="classification" control={Select} value={registrant.classification} options={classificationList} label="Classification" fluid onChange={handleFormChange} />
                            <Form.Field id="teacherLastName" control={Input} value={registrant.teacherLastName} label="Teacher's Last Name" fluid onChange={handleFormChange} />
                            <Form.Field id="teacherEmailAddress" control={Input} value={registrant.teacherEmailAddress} label="Teacher's Email Address" fluid onChange={handleFormChange} onBlur={validateEmail} />
                            <Form.Field id="scotsDanceNumber" control={Input} value={registrant.scotsDanceNumber} label="ScotsDance # (2023 number is fine*)" fluid onChange={handleFormChange} />
                            <p style={{fontSize: 12, fontStyle: 'italic'}}><span style={{fontSize: 18}}>*</span> Must have 2024 ScotDance card at time of check-in on competition day.</p>
                            
                            
                            

                            {/*<DateInput label="Effective Date" id="effectivedate" placeholder="Select Effective Date" value={settings.effectivedate} onChange={this.handleFormChange} dateFormat="MM/DD/YYYY" closable={true} icon={false} animation="none" error={(this.state.submitted && !settings.effectivedate) ? true : false} />*/}
                        </Form> 
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={preparePageTwo} disabled={checkPage1Disabled()}>Next</Button>
                    </Modal.Actions>
                </>
            }
    
            {page === 2 &&
                <>
                    <Header as="h3" textAlign="center">{registrant.firstName}'s Competition  Choices</Header>
                    <Modal.Content>
                        <Form style={{width: 250, margin: '0 auto'}}>
                            <p style={{textAlign: 'center', margin: '0 0 4px 0', fontWeight: 'bold'}}>Saturday 03/02/2024</p>
                            {danceList[0].length === 0 && <p style={{textAlign: 'center', margin: '0 0 4px 0', fontStyle: 'italic'}}>No dances available for this classification</p>}
                            {danceList[0].map((dance, index) => {
                                return (
                                    <Form.Field key={index} control={Checkbox} label={`${dance.name} ($${dance.price})`} checked={registrant.dances[dance.name]} onChange={() => handleDanceClick(dance.name, dance.price)} />
                                );
                            })}
                            <p style={{textAlign: 'center', margin: '30px 0 4px 0', fontWeight: 'bold'}}>Sunday 03/03/2024</p>
                            {danceList[1].length === 0 && <p style={{textAlign: 'center', margin: '0 0 4px 0', fontStyle: 'italic'}}>No dances available for this classification</p>}
                            {danceList[1].map((dance, index) => {
                                return (
                                    <Form.Field key={index} control={Checkbox} label={`${dance.name} ($${+dance.price})`} checked={registrant.dances[dance.name]} onChange={() => handleDanceClick(dance.name, dance.price)} />
                                );
                            })}
                        </Form>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => setPage(1)}>Back</Button>
                        <Button primary onClick={() => setPage(3)} disabled={checkPage2Disabled()}>Next</Button>
                    </Modal.Actions>
                </>
            }

            {page === 3 &&

                <>
                    <Header as="h3" textAlign="center">Official Entry Form</Header>
                    <Modal.Content>
                        <p style={{margin: '0 auto', width: 400, textAlign: 'center', fontWeight: 'bold'}}>Signature is required for registration.</p>

                        <p style={{margin: '0 auto', width: 400, textAlign: 'center', fontSize: 12}}>The individual/organization named in this form hereby agrees for itself, successors and assignees, to indemnify and hold harmless the Caledonian Society of Arizona, from all claims, suits or any other action for COVID- related or personal injuries, including death, and damages to property, real or personal, caused by Individual/Organizations involvement in the Phoenix Scottish Games on March 4 & 5, 2023 and from all judgments and costs incurred in relation to said claims and suits; and from all expenses incurred in defending said claims or suits. I authorize the Caledonian Society of Arizona to take and use whatever photographs, video, and audio recordings of me participating for whatever purpose they deem appropriate. I have read and agree to abide by the rules and procedures set forth for this competition.</p>
                        <p style={{margin: '10px auto 10px auto', width: 400, textAlign: 'center', fontSize: 12}}>Competitor signature or parent/guardian if competitor is under 18 years old.</p>
                        <Form style={{width: 250, margin: '0 auto'}}>
                            <Form.Field id="signature" control={Input} value={registrant.signature} label="Sign By Entering First And Last Name" fluid onChange={handleFormChange} />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => setPage(2)}>Back</Button>
                        <Button primary onClick={handleSubmit} disabled={checkPage3Disabled()}>Done</Button>
                    </Modal.Actions>
                </>
            }

            {confirmModal &&
                <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
                    <Header as="h3" textAlign="center">Are You Sure You Want To Cancel?</Header>
                    <Modal.Actions>
                        <Button color="blue" onClick={() => setModal('')}>Yes!</Button>
                        <Button negative onClick={() => setConfirmModal(false)}>No</Button>
                    </Modal.Actions>
                </Modal>
            }
            <p style={{position: 'absolute', bottom: 10, left: 10, fontSize: 20}}>Total: ${registrant.total}</p>
        </Modal>
    );
}

export default Registration;