const registrantTemplate = {
    firstName: '',
    lastName: '',
    birthDate: '',
    address: '',
    classification: '',
    teacherLastName: '',
    teacherEmailAddress: '',
    scotsDanceNumber: '',
    dances: {},
    signature: '',
    total: 0
};

export default registrantTemplate;