import React, { useState } from 'react';
import { Icon, Modal, Header, Button } from 'semantic-ui-react';
import Registration from './registration';
import Checkout from './checkout';



const Cart = ({cart, cartTotal, removeDancerFromCart, modifyDancerInCart, masterDanceList, setLoading, setCart}) => {

    const [confirmModal, setConfirmModal] = useState('');
    const [editModal, setEditModal] = useState('');
    const [checkoutModal, setCheckoutModal] = useState(false);

    const getDanceCount = (dances) => {
        let count = 0;
        const danceKeys = Object.keys(dances);
        for (let i = 0; i < danceKeys.length; i++) {
            if (dances[danceKeys[i]]) count++;
        }
        return count;
    }

    const removeDancer = () => {
        removeDancerFromCart(confirmModal);
        setConfirmModal('');
    }
    
    if (cart.length > 0) return (
        <>
            <div style={{width: '100%', height: 20}}></div>
            <Button color="blue" style={{marginTop: 0}} onClick={() => setCheckoutModal(true)}>Checkout</Button>
            <p>Total: ${cartTotal}</p>

            <div style={{margin: '40px auto 0 auto', width: '40vw', minWidth: 300}}>
                <p style={{marginBottom: 0, fontSize: 16, fontWeight: 'bold'}}>Dancers</p>
                {cart.map((dancer, index) => {
                    return (
                        <div key={index} style={{position: 'relative', marginTop: index === 0 ? 0 : 10, width: '100%', height: 80, borderRadius: 3, border: '1px solid rgba(34,36,38,.15)', boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)'}}>
                            <span style={{position: 'absolute', top: 2, left: '50%', transform: 'translateX(-50%)'}}>{dancer.firstName + ' ' + dancer.lastName}</span>
                            <span style={{position: 'absolute', top: '50%',left: '50%', transform: 'translate(-50%, -50%)'}}>{getDanceCount(dancer.dances)} competition{getDanceCount(dancer.dances) > 1 ? 's' : ''}</span>
                            <span style={{position: 'absolute', bottom: 2, left: '50%', transform: 'translateX(-50%)'}}>{'Total: $' + dancer.total}</span>
                            <Icon name="edit" color="blue" style={{position: 'absolute', top: 2, right: 2, cursor: 'pointer', fontSize: 12}} onClick={() => setEditModal(index)} />
                            <Icon name="trash alternate outline" color="red" style={{position: 'absolute', bottom: 8, right: 2, cursor: 'pointer', fontSize: 12}} onClick={() => setConfirmModal(index)} />
                        </div>
                    );

                })}
            </div>

            {confirmModal !== '' &&
                <Modal size="small" open closeOnDimmerClick={false} closeOnEscape={false}>
                    <Header as="h3" textAlign="center">Are You Sure You Want To Remove {cart[confirmModal].firstName}?</Header>
                    <Modal.Actions>
                        <Button color="blue" onClick={removeDancer}>Yes!</Button>
                        <Button negative onClick={() => setConfirmModal('')}>No</Button>
                    </Modal.Actions>
                </Modal>
            }

            {editModal !== '' && <Registration setModal={setEditModal} masterDanceList={masterDanceList} dancerIndex={editModal} modifyDancerInCart={modifyDancerInCart} dancerInfo={cart[editModal]} />}

            {checkoutModal && <Checkout setCheckoutModal={setCheckoutModal} setLoading={setLoading} cartTotal={cartTotal} cart={cart} setCart={setCart} />}
        </>
        
    );
}

export default Cart;