import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import apiString from '../components/apiString';
import { generateLineUpSheets, generateResultSheets } from '../components/printFunctions';

const compConverter = {
    open: 'Open Champs',
    jig: 'Jig',
    prechamps: 'Pre-Champs',
    precomp: 'Pre-Premier Comp',
    national: 'Nationals'
};


const EventDay = ({setLoading}) => {

    const [sheets, setSheets] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        setLoading(true);
        getStatus().then(() => {
            setLoading(false);
        });
        setInterval(() => {
            getStatus();
        }, 5000);
    }, []);

    const getStatus = () => {
        return new Promise((resolve, reject) => {
            let pulls = [];
            const data = new FormData();
            data.set('action', 'getLineupSheetStatus');
            pulls.push(Axios.post(apiString, data));

            let data2 = new FormData();
            data2.set('action', 'getResultSheetStatus');
            pulls.push(Axios.post(apiString, data2));
            // Axios.post(apiString, data).then(response => {
            Axios.all(pulls).then(response => {
                setSheets(response[0].data);
                setResults(response[1].data);
                resolve();
            });
        });
        
    }

    const handleLineupSheetClick = async comp => {
        setLoading(true);
        const pdf = await generateLineUpSheets(comp);
        window.open(pdf.output('bloburl'), '_blank');
        setLoading(false);
    }

    const handleResultSheetClick = async comp => {
        setLoading(true);
        const pdf = await generateResultSheets(comp);
        window.open(pdf.output('bloburl'), '_blank');
        setLoading(false);
    }

    const divHeight = 6;
    
    return (
        <>
            <div style={{width: '100%', height: 20}}></div>
            
            <div style={{width: 400, border: '1px solid #bcbcbc', borderRadius: 3, margin: '0 auto', padding: '4px 12px 12px 12px', textAlign: 'center'}}>
                <p style={{fontWeight: 'bold', fontSize: 15, marginBottom: 12}}>Line Up Sheets</p>
                {sheets.length === 0 && <p style={{marginTop: 0, marginBottom: 0}}>No line up sheets available</p>}
                {sheets.length > 0 && sheets.map((comp, index) => {
                    return <p key={index} style={{marginTop: 0, marginBottom: 0, cursor: 'pointer', color: '#0494e1'}} onClick={() => handleLineupSheetClick(comp.competition)}>{compConverter[comp.competition]}</p>
                })}
            </div>

            <div style={{width: 400, border: '1px solid #bcbcbc', borderRadius: 3, margin: '30px auto', padding: '4px 12px 12px 12px', textAlign: 'center'}}>
                <p style={{fontWeight: 'bold', fontSize: 15, marginBottom: 12}}>Result Sheets</p>
                <a href="http://www.phoenixhighlanddance.com/images/IMG_9461.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>7 & Under 10 Laddie/Lilt</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9462.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>7 & Under 10 Earl/Hornpipe</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9465.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>10 & Under 12 Laddie/Lilt</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9466.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>10 & Under 12 Earl/Hornpipe</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9467.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>12 & Under 14 Laddie/Lilt</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9468.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>12 & Under 14 Earl/Hornpipe</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9469.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>14 & Under 16 Laddie/Lilt</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9470.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>14 & Under 16 Earl/Hornpipe</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9471.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>16 Yrs Laddie/Lilt</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9472.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>16 yrs Earl/Hornpipe</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9475.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>17 & Over 10 Laddie/Lilt</a>
                <div style={{width: '100%', height: divHeight}}></div>

                <a href="http://www.phoenixhighlanddance.com/images/IMG_9476.jpg" rel="noreferrer" target="_blank" style={{color: '#0494e1', fontSize: 13}}>17 & Over 10 Earl/Hornpipe</a>

                {/* {results.length === 0 && <p style={{marginTop: 0, marginBottom: 0}}>No result sheets available</p>} */}
                {/*results.length > 0 && results.map((comp, index) => {
                    return <p key={index} style={{marginTop: 0, marginBottom: 0, cursor: 'pointer', color: '#0494e1'}} onClick={() => handleResultSheetClick(comp.competition)}>{compConverter[comp.competition]}</p>
                })*/}
            </div>
        </>
    );
}

export default EventDay;