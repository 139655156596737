import Contact from '../components/contact';

const Schedule = () => {
    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>


            <p style={{marginBottom: 0}}><strong>Saturday March 2nd</strong></p>
            <p style={{marginBottom: 0}}>Registration 8:30 am</p> 
            <p style={{marginBottom: 0}}>Dancing begins at 9:00 am</p>
            <p style={{marginBottom: 0}}></p>
            <p style={{marginBottom: 20}}>Phoenix Open Championship</p>
            <p style={{marginBottom: 0}}>7 & under 10</p>
            <p style={{marginBottom: 0}}>10 & under 12</p>
            <p style={{marginBottom: 0}}>12 & under 14</p>
            <p style={{marginBottom: 0}}>14 & under 16</p>
            <p style={{marginBottom: 0}}>16 & under 18</p>
            <p style={{marginBottom: 0}}>18 & over</p> 
            <p style={{marginBottom: 0}}>Championship awards</p> 
            <p style={{marginBottom: 0}}></p>
            <p style={{marginBottom: 0}}>Irish Jig Challenge- 5 step</p>
            <p style={{marginBottom: 20}}>(challenge will follow conclusion of championship awards)</p>
            <p style={{marginBottom: 0}}><strong>Sunday March 3rd</strong></p>
            <p style={{marginBottom: 0}}>Registration: 8:30 am</p>
            <p style={{marginBottom: 20}}>Dancing begins at 9:00 am</p>
            <p style={{marginBottom: 0}}><strong>Pre-Premier</strong></p>
            <p style={{marginBottom: 0}}>Primary:</p>
            <p style={{marginBottom: 0}}>16 PDB</p> 
            <p style={{marginBottom: 0}}>PDB & HC</p>
            <p style={{marginBottom: 0}}>Highland Fling (4)</p>
            <p style={{marginBottom: 0}}>Sword (2&1)</p>
            <p style={{marginBottom: 20}}>(Primary dancers will register and dance at a separate time during the morning competition, time is TBD)</p> 
            <p style={{marginBottom: 0}}>Beginner:</p>
            <p style={{marginBottom: 0}}>Highland Fling (4)</p>
            <p style={{marginBottom: 0}}>Sword (2&1)</p>
            <p style={{marginBottom: 0}}>Sean Triubhas (3&1)</p>
            <p style={{marginBottom: 20}}>Scottish Lilt (4)</p>
            <p style={{marginBottom: 0}}>Novice:</p>
            <p style={{marginBottom: 0}}>Highland Fling (4)</p>
            <p style={{marginBottom: 0}}>Sword (2&1)</p>
            <p style={{marginBottom: 0}}>Sean Triubhas (3&1)</p>
            <p style={{marginBottom: 20}}>Flora MacDonald (4)</p>
            <p style={{marginBottom: 0}}>Intermediate:</p>
            <p style={{marginBottom: 0}}>Highland Fling (4)</p>
            <p style={{marginBottom: 0}}>Sword (2&1)</p>
            <p style={{marginBottom: 0}}>Sean Triubhas (3&1)</p>
            <p style={{marginBottom: 20}}>Sailors Hornpipe (4)</p>
            <p style={{marginBottom: 0}}><strong>Pre-Championship:</strong></p>
            <p style={{marginBottom: 0}}>Highland Fling (4)</p>
            <p style={{marginBottom: 0}}>Sword (2&1)</p>
            <p style={{marginBottom: 0}}>Sean Triubhas (3&1)</p>
            <p style={{marginBottom: 20}}>Reel of Tulloch</p>
            <p style={{marginBottom: 0}}><strong>Scottish National Dance Premiership:</strong></p>
            <p style={{marginBottom: 0}}>Registration at 11:00 am</p>
            <p style={{marginBottom: 20}}>Dancing begins immediately following the pre-premier awards</p>
            <p style={{marginBottom: 0}}>7 & under 10</p>
            <p style={{marginBottom: 0}}>10 & under 12</p>
            <p style={{marginBottom: 0}}>12 & under 14</p>
            <p style={{marginBottom: 0}}>14 & under 16</p>
            <p style={{marginBottom: 0}}>16 & under 18</p>
            <p style={{marginBottom: 0}}>18 & over</p>
            
            <Contact />
        </div>
    );
}

export default Schedule;