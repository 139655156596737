import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

const Spinner = props => {
    return (
        <Dimmer active page>
            <Loader size="huge" content={props.text} />
        </Dimmer> 
    );
}

export default Spinner;