import schedule from '../images/schedule.pdf';


import Contact from '../components/contact';

const Info = () => {
    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>

            <p style={{fontSize: 22, fontWeight: 'bold', marginBottom: 0}}>Schedule</p>
            <p>Click <a href={schedule} rel="noreferrer" target="_blank" style={{fontWeight: 'bold'}}>here</a> for the schedule</p>

            <p style={{fontSize: 22, fontWeight: 'bold', marginBottom: 0, marginTop: 30}}>Location</p>
            <p style={{marginBottom: 0}}>The contest is held at Gilbert Regional Park in Gilbert, AZ:</p>
            <p style={{marginBottom: 0}}>3005 E. Queen Creek Road</p>
            <p style={{marginBottom: 0}}>Gilbert, AZ 85298</p>
            <p>480.503.6200</p>
            <p>The dance area will be in the same spot as 2022: directly across the field from the main gate.</p>

            {/*<p style={{fontSize: 22, fontWeight: 'bold', marginBottom: 0, marginTop: 30}}>Host Hotel</p>
            <a target="_blank" rel="noreferrer" href="https://www.hilton.com/en/attend-my-event/phxchhf-icsa-3907b80c-4875-4139-88db-6051a31dc445/" style={{marginBottom: 0}}>Hilton Chandler</a>*/}

            <p style={{fontSize: 22, fontWeight: 'bold', marginBottom: 0, marginTop: 30}}>Tickets</p>
            <p style={{marginBottom: 0}}>Parents: we strongly encourage you to purchase your tickets in advance, tickets can be purchased here: www.arizonascots.com</p>
            <p>(All registered dancers will receive a two-day entry to games along with their dance teacher)</p>

            <p style={{fontSize: 22, fontWeight: 'bold', marginBottom: 0, marginTop: 30}}>Prize Money</p>
            <p style={{marginBottom: 0}}><strong>Saturday Premier Championship</strong></p>
            <p>1st: $50, 2nd: $40, 3rd: $30</p>
            <p style={{marginBottom: 0}}><strong>Irish Jig Challenge</strong></p>
            <p style={{marginBottom: 0}}><strong>Both age groups, 15& under and 16& over</strong></p>
            <p>1st: $100, 2nd: $50, 3rd: $25</p>
            <p style={{marginBottom: 0}}><strong>Sunday Premiership</strong></p>
            <p>1st: $40, 2nd: $30, 3rd: $20</p>
            <p>Pre-Championship aggregate winners in each age class will receive a $50 scholarship (age classes will be determined after all entries are received)</p>
            <p>Medals awarded for 1st, 2nd and 3rd place winners in Pre-Premier. Ribbons awarded for 4th, 5th and 6th  place winners in Pre-Premier and Premier</p>
            
            <p style={{fontSize: 22, fontWeight: 'bold', marginBottom: 0, marginTop: 30}}>Canopies</p>
            <p>If you are traveling from out of town and would like to reserve a canopy to rent for the weekend, please contact us at phxchampion@gmail.com- payment for the canopies will be collected at the time of registration. $35 per day or $60 for the weekend.</p>

            <Contact />
        </div>
    );
}

export default Info;