import Contact from '../components/contact';

const Location = () => {
    return (
        <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
            <p>The contest is held at Gilbert Regional Park in Gilbert, AZ</p>
            <p style={{marginBottom: 0}}>3005 E. Queen Creek Road</p>
            <p style={{marginBottom: 0}}>Gilbert, AZ 85298</p>
            <p>480.503.6200</p>
            <p>The dance area will be in the same spot as 2022: directly across the field from the main gate.</p>
            <p>Hotel information is coming soon.</p>
            
            <Contact />
        </div>
    );
}

export default Location;